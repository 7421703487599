import React from 'react';
import PropTypes from 'prop-types';

import styles from './MoreGoodness.module.css';
import Title from '../../../../elements/Title';
import Text from '../../../../elements/Text';

const MoreGoodness = ({ items, withoutTitle, displayOnMobile }) => (
  <div className={displayOnMobile ? '' : styles.moreGoodness}>
    {!withoutTitle && <Title type="big" className={styles.title}>More Portal Goodness</Title>}
    <div className="row">
      {
        items.map((item, index) =>
          <div className="col-lg-4 col-xl-4 col-md-6 col-12 p-0 d-flex justify-content-center">
            <div className={styles.item}>
              <div className={styles.item__number} style={{ backgroundColor: item.color }}>{index + 1}</div>
              <div className={styles.item__title}>{item.title}</div>
              <Text className={styles.item__text}><span dangerouslySetInnerHTML={{ __html: item.text }} /></Text>
            </div>
          </div>
        )
      }
    </div>
  </div>
);

MoreGoodness.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  withoutTitle: PropTypes.bool,
  displayOnMobile: PropTypes.bool
}

MoreGoodness.defaultProps = {
  withoutTitle: false,
  displayOnMobile: false
}

export default MoreGoodness;
