import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import styles from './CustomerExperience.module.css';
import Title from '../../../../elements/Title';
import Text from '../../../../elements/Text';
import Image from '../../../../elements/Image';
import Container from '../../../../blocks/Container';

const CustomerExperience = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/portal/customer-expirience.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              body
            }
          }
        }
      }
    }
  `);

  const { title, body } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.container}>
      <Container type="big">
        <Container type="mobile" className="row d-flex justify-content-center align-items-center">
          <div className="col-xl-6 col-lg-6 col-12 px-xl-0 d-flex justify-content-end order-xl-0 order-lg-0 order-1">
            <Image name="customer-expirience-window" className={styles.imageDiv} />
          </div>
          <div className={`${styles.textContainer} col-xl-6 col-lg-6 col-12 px-xl-0 order-xl-1 order-lg-1 order-0`}>
            <div className={styles.offerText}>
              <Title className={styles.title}>{title}</Title>
              <Text className={styles.body}>{body}</Text>
            </div>
          </div>
          <Image name="customer-expirience-woman" className={styles.imageWoman}  />
          <Image name="customer-expirience-woman-small" className={styles.imageWomanSmall} />
        </Container>
      </Container>
    </div>
  );
};

export default CustomerExperience;
