import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import styles from './PortalWebsite.module.css';
import Title from '../../../../elements/Title';
import Text from '../../../../elements/Text';
import Image from '../../../../elements/Image';

const PortalWebsite = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/portal/portal-website.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              body
            }
          }
        }
      }
    }
  `);

  const { title, body } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={`${styles.container}`}>
      <div className={styles.titleContainer}>
        <Title className={styles.title}>{title}</Title>
        <Text className={styles.textBlock}>{body}</Text>
        <div className={styles.confettiTitle}>
          <Image name="website-confetti-top" />
        </div>
      </div>
      <div className={`${styles.imagesContainer}`}>
        <div className="d-flex">
          <div className={styles.imageWindow}>
            <Image name="portal-website-window" />
          </div>
          <div className={styles.imageMobile}>
            <Image name="portal-website-mobile" />
          </div>
        </div>
        <div className={styles.flowersImage}><Image name="portal-website-flowers" /></div>
        <div className={styles.flowersImageSmall}><Image name="portal-website-flowers-small" /></div>
        <div className={styles.linesImage}><Image name="portal-website-lines" /></div>
        <div className={styles.confettiLeftImage}><Image name="website-confetti-window-left" /></div>
        <div className={styles.confettiBottomImage}><Image name="website-confetti-window-bottom" /></div>
        <div className={styles.confettiRightImage}><Image name="website-confetti-window-right" /></div>
        <div className={styles.confettiMobileBottom}><Image name="website-confetti-mobile-bottom" /></div>
      </div>
      <div className={styles.confettiMobileTop}><Image name="website-confetti-mobile-top" /></div>
    </div>
  );
};

export default PortalWebsite;
