import React from 'react';
import PropTypes from 'prop-types';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './PortalCustomization.module.css';
import Container from '../../../blocks/Container';
import Image from '../../../elements/Image';
import Title from '../../../elements/Title';
import Text from '../../../elements/Text';
import MoreGoodness from '../elements/MoreGoodness';

const PortalCustomization = ({ showMoreGoodness }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/portal-customization.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              subtitle
              mainItems {
                image
                imageWidth
                title
                text
              }
              otherItems {
                color
                title
                text
              }
            }
          }
        }
      }
    }
  `);

  const { title, subtitle, mainItems, otherItems } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={`${styles.portalCustomization} ${!showMoreGoodness ? styles.portalCustomizationWithoutMoreGoodness : ''}`}>
      <Container>
        <Container type="mobile" className="d-flex flex-column align-items-center">
          <Title type="small" className={styles.title}>
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </Title>
          <Text className={styles.subtitle}>{subtitle}</Text>
          <div className="row">
            {
              mainItems.map((item, index) =>
                <div key={index} className={`${styles.itemContainerWrapper} col-xl-6 col-lg-6 col-12`}>
                  <div className={`${styles.itemContainer} ${[0,1].includes(index) ? styles.itemContainerTop : ''}`}>
                    <div className={styles.item}>
                      <Image name={item.image} className={styles.itemImage} style={{ width: item.imageWidth }} />
                      <Title className={styles.itemTitle}>{item.title}</Title>
                      <Text>{item.text}</Text>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
          {showMoreGoodness && <MoreGoodness items={otherItems} />}
        </Container>
      </Container>
    </div>
  )
}

PortalCustomization.defaultProps = {
  showMoreGoodness: true
}

PortalCustomization.propsTypes = {
  showMoreGoodness: PropTypes.bool
}

export default PortalCustomization;
