import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import styles from './PortalHeader.module.css';
import Title from '../../../../elements/Title';
import Text from '../../../../elements/Text';
import Image from '../../../../elements/Image';
import Container from '../../../../blocks/Container';

const PortalHeader = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/portal/portal-header.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              lists {
                item {
                  strong
                  text
                }
              }
            }
          }
        }
      }
    }
  `);

  const { title, lists } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <Container type="big" className={`${styles.container} d-flex justify-content-center align-items-center`}>
      <Container type="mobile">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-12 pr-xl-0 pr-lg-0 pl-xl-3 pl-lg-5 p-md-5">
            <Title className={styles.title}>{title}</Title>
            <Text className={styles.textBlock}>
              <ul className={`${styles.textBlockList}`}>
                {lists.map((list, index) => (
                  <li key={index}><strong>{list.item.strong}</strong> {list.item.text}</li>
                ))}
              </ul>
            </Text>
          </div>
          <div className="col-xl-6 col-lg-6 col-12">
            <Image name="portal-header" className={styles.imageDiv} />
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default PortalHeader;
