
import React from 'react';
import Layout from '../../components/blocks/Layout';
import SEO from '../../components/blocks/SEO';
import CustomerExperience from '../../components/slides/features/portal/CustomerExperience';
import PortalHeader from '../../components/slides/features/portal/PortalHeader';
// import PortalHelps from '../../components/slides/features/portal/PortalHelps/PortalHelps';
import PortalWebsite from '../../components/slides/features/portal/PortalWebsite';
import PortalCustomization from '../../components/slides/portal-launch/PortalCustomization';

const Portal = () => (
  <Layout>
    <SEO
      title="Portal | Deadline Funnel"
      description="Delight Your Customers and Grow Your Business With Deadline Funnel + Portal!"
    />
    <PortalHeader />
    <CustomerExperience />
    <PortalWebsite />
    <PortalCustomization showMoreGoodness={false} />
    {/*<PortalHelps />*/}
  </Layout>
);

export default Portal;